<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row dense>
      <v-col cols="12">
        <v-breadcrumbs class="py-2" :items="breadcrumbs">
          <template v-slot:item="{ item }">
            <router-link :to="item.href" class="v-breadcrumbs__item">
              {{ item.text }}
            </router-link>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <v-card class="mt-0 mb-1">
          <v-card-title>
            <span class="text-truncate">{{ title }}</span>
          </v-card-title>
          <v-card-text>
            <v-divider />
            <v-row>
              <v-col :md="false">
                <v-simple-table class="mt-3" dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in infos" :key="item.uid">
                        <td class="font-weight-bold">
                          {{ $t(item.label) }}
                        </td>
                        <td>
                          <router-link
                            v-if="item.flds"
                            :to="
                              '/cautare-avansata?c=art&f1=' +
                                item.flds +
                                '&s1==&v1=' +
                                item.value +
                                '&f2=700a&s2=a&v2=&f3=606a&s3=a&v3=&o=&d=&u=ca'
                            "
                            class="v-breadcrumbs__item"
                          >
                            {{ item.value }}
                          </router-link>
                          <template v-else>
                            {{ item.value }}
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col v-if="terms.length > 0" cols="12" md="4">
                <v-simple-table class="mt-3" dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in terms" :key="item.uid">
                        <td class="font-weight-bold">
                          {{ $t(item.label) }}
                        </td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="12"> </v-col>
              <v-col cols="8" sm="6" md="4" lg="3" xl="2" class="d-flex justify-center">
                <v-btn color="red" class="mr-2" fab x-small @click="addToFavorite">
                  <v-icon v-if="isFavorites" size="20">mdi-heart</v-icon>
                  <v-icon v-else size="20">mdi-heart-outline</v-icon>
                </v-btn>
                <a class="v-breadcrumbs__item" href="http://www.facebook.com/sharer.php?u=" target="_blank">
                  <v-btn color="blue" elevation="5" icon small>
                    <v-icon size="37">mdi-facebook</v-icon>
                  </v-btn>
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="mt-0 mb-1">
          <v-card-text>
            <v-tabs>
              <v-tab><v-icon class="mr-1 mt-1">mdi-playlist-plus</v-icon>{{ $t("Detalii") }}</v-tab>
              <v-tab><v-icon class="mr-1 mt-1">mdi-format-list-numbered</v-icon>{{ $t("Unimarc") }}</v-tab>
              <v-tab><v-icon class="mr-1 mt-1">mdi-view-compact</v-icon>{{ $t("Vizualizare compactă") }}</v-tab>

              <v-tab-item>
                <v-simple-table class="mt-3" dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in details" :key="item.uid">
                        <td :class="item.value ? 'pl-6' : 'font-weight-bold'">
                          {{ $t(item.label) }}
                        </td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item>
                <div v-for="uni in unimarc" :key="uni.uid">
                  <span class="teal--text font-weight-bold">
                    {{ uni.fld + " " }}
                  </span>
                  <span class="grey--text font-weight-bold">
                    {{ uni.ind1 + uni.ind2 + " " }}
                  </span>
                  <div v-for="sub in uni.subs" :key="sub.uid" class="d-inline-flex mr-1">
                    <span class="pink--text font-weight-bold">
                      {{ "$" + sub.sub }}
                    </span>
                    <span> {{ sub.val + " " }}</span>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div>035a</div>
                <div>700a</div>
                <div>200a+.200c+=200d+:200e+/200f+;200g</div>
                <div>205a+,205b+=205d+/205f+;205g</div>
                <div>210a+;210a+:210c+,210d+(210efgh)+;210f+:210g+,210h</div>
                <div>215a+,215a+:215c+;215d++215e</div>
                <div>(225a+=225d+:225e+/225f+;225f+;225v+,225x)</div>
                <div>010,011,013</div>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      schemaContent: {},
      uid: 1,
      recordId: "",
      record: {},
      title: "",
      unimarc: [],
      uninot: ["916", "917", "006", "856"],
      details: [],
      infos: [],
      terms: [],
      inventories: [],
      dictionary: []
    }
  },
  computed: {
    isFavorites() {
      if (this.userFavorites) {
        if (this.userFavorites.indexOf(this.record._id) > -1) {
          return true
        }
      }
      return false
    },
    userFavorites() {
      return this.$store.getters.userFavoritesArt
    },
    userWebId() {
      return this.$store.getters.userWebId
    },
    companyId() {
      return this.$store.getters.companyId
    },
    baseURL() {
      return axios.defaults.baseURL
    },
    appSchema() {
      return this.$store.getters.appSchema
    },
    breadcrumbs() {
      const arr = [
        {
          text: "Acasă",
          disabled: false,
          href: "/"
        },
        {
          text: "Articole",
          disabled: false,
          href: "/art"
        }
      ]
      if (this.record.ctg != "" && this.dictionary.find(itm => itm.value == this.record.ctg)) {
        arr.push({
          text: this.dictionary.find(itm => itm.value == this.record.ctg).name,
          disabled: false,
          href: "/art/cat-" + this.record.ctg
        })
      }
      return arr
    }
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.getRecords()
      }
    },
    $route() {
      //this.$log("route changed ", this.$route)
      if (this.$route.name == "Articol") {
        this.unimarc = []
        this.infos = []
        this.details = []
        this.terms = []
        this.inventories = []
        this.recordId = this.$route.params.pathMatch
        this.getRecord()
      }
    }
  },
  created() {
    this.recordId = this.$route.params.pathMatch
    this.generateSchemaContent(this.appSchema.filter(itm => itm.collection == "marc_art")[0].content.fields.schema)
    this.getdictionary("categorii_art").then(res => {
      this.dictionary = res
    })
    //this.$log("scgcon2 ", this.schemaContent)
    this.getRecord()
  },
  methods: {
    addToFavorite() {
      if (this.userWebId) {
        const fav = [...this.userFavorites]
        if (this.isFavorites) {
          fav.splice(fav.indexOf(this.record._id), 1)
        } else {
          fav.push(this.record._id)
        }
        this.$store.commit("userFavoritesArt", fav)
        this.$store.dispatch("setUserFavoritesArt")
      }
    },
    getdictionary(dic) {
      return new Promise((resolve, reject) => {
        if (this.$store.getters.dictionary("all_" + dic)) {
          resolve(this.$store.getters.dictionary("all_" + dic))
        } else {
          axios
            .get('app_dictionary?where={"value":"' + dic + '"}')
            .then(response => {
              if (response.data._items[0]) {
                this.$store.commit("dictionary", { name: "all_" + dic, values: response.data._items[0].content })
                resolve(response.data._items[0].content)
              }
            })
            .catch(err => {
              this.$log("errrrrrr ", err)
              reject(new Error("Dictionary not found"))
            })
        }
      })
    },
    getRecord() {
      axios.get("ebib_marc_art/" + this.recordId).then(response => {
        //ebib_marc_inv?projection={"fields.100.a.val":1,"fields.200.a.val":1,"fields.500.a.val":1,"fields.505.a.val":1,"fields.210.a.val":1,"fields.700.a.val":1}&where={"fields.400.link":"5f34da1e576a33941a6970dc"}&max_results=100&page=1
        //this.records = []
        this.record = response.data
        this.title = this.record.fields["200"][0].a[0].val
        this.$log("record ", this.record)
        this.addDetails("Autor", "700", "a", true)
        this.addDetails("Editura", "210", "c", true)
        this.addDetails("Loc publicare", "210", "a", true)
        this.addDetails("An", "210", "d", true)
        this.addDetails("Subiect", "606", "a", true)
        this.addDetails("Subiecte", "610", "a", true)
        this.addDetails("ISBN", "010", "a")
        this.addDetails("Note", "300", "a")
        let bool = false
        for (const fldi in this.record.fields["606"]) {
          this.terms.push({
            label: bool ? "" : "Termeni de subiect",
            value: this.record.fields["606"][fldi].a[0].val,
            uid: this.uid
          })
          bool = true
          this.uid++
        }
        for (const flds in this.record.fields) {
          if (this.uninot.indexOf(flds) < 0) {
            for (const fld in this.record.fields[flds]) {
              const rec = { fld: flds, subs: [], ind1: "_", ind2: "_", uid: this.uid++ }
              this.details.push({ label: this.schemaContent[flds].label, uid: this.uid++ })
              for (const sub in this.record.fields[flds][fld]) {
                if (sub.length == 1) {
                  for (const subs in this.record.fields[flds][fld][sub]) {
                    this.details.push({
                      label: this.schemaContent[flds + sub].label,
                      value: this.record.fields[flds][fld][sub][subs].val,
                      uid: this.uid++
                    })
                    rec.subs.push({ sub: sub, val: this.record.fields[flds][fld][sub][subs].val, uid: this.uid++ })
                    //rec.subs += "$" + sub + this.record.fields[flds][fld][sub][subs].val + " "
                  }
                } else {
                  if (sub == "ind1") {
                    rec.ind1 = this.record.fields[flds][fld][sub]
                  }
                  if (sub == "ind2") {
                    rec.ind2 = this.record.fields[flds][fld][sub]
                  }
                }
              }
              this.unimarc.push(rec)
            }
          }
        }
        this.unimarc.sort((a, b) => {
          return a.fld - b.fld
        })
      })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    addDetails(lbl, fld, sub, details) {
      let bool = false
      for (const fldi in this.record.fields[fld]) {
        for (const subi in this.record.fields[fld][fldi][sub]) {
          this.infos.push({
            label: bool ? "" : lbl,
            flds: details ? fld + sub : undefined,
            value: this.record.fields[fld][fldi][sub][subi].val,
            uid: this.uid++
          })
          bool = true
        }
      }
    },
    generateSchemaContent(schema) {
      for (const fld in schema) {
        this.schemaContent[fld] = { label: fld }
        if (schema[fld].meta) {
          if (schema[fld].meta.label) {
            this.schemaContent[fld].label = schema[fld].meta.label
          }
        }
        if (schema[fld].schema != undefined) {
          if (schema[fld].schema.schema) {
            for (const sub in schema[fld].schema.schema) {
              if (sub.length == 1) {
                this.schemaContent[fld + sub] = { label: fld + sub }
                if (schema[fld].schema.schema[sub].schema.schema.val.meta) {
                  if (schema[fld].schema.schema[sub].schema.schema.val.meta.label) {
                    this.schemaContent[fld + sub].label = schema[fld].schema.schema[sub].schema.schema.val.meta.label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>
<style></style>
